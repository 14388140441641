import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

//bootstrap imports
import { Row, Col, Image } from "react-bootstrap"

//import images for header
import LogoMetLijnen2 from "./../images/logo/studio-logo.png"
import LogoMetLijnen1 from "./../images/logo/manon-logo.png"

import "./../components/layout.css"
import 'bootstrap/dist/css/bootstrap.min.css';


const header = ({ siteTitle }) => (
  <header>
    <Row>
      <Col xxl={6} xl={6} lg={6} md={6} s={12} xs={12} className="mt-3 text-end pe-1 ps-1 mt-2">
        <Link to="/"><Image src={LogoMetLijnen2}
         style={{width: '100%'}}
          className="mx-auto"/></Link>
      </Col>
      <Col xxl={6} xl={6} lg={6} md={6} s={12} xs={12} className="mt-3 text-start pe-1 ps-1">
      <Link to="/"><Image src={LogoMetLijnen1}
       style={{width: '100%'}}
        className="mx-auto"/></Link>
      </Col>
    </Row>

    <Row className=" p-0">
      <Col xxl={4} xl={4} lg={4} md={4} s={4} xs={4} className="pe-1 ps-1 mt-1 mb-1">
        <Link to="/" className="text-uppercase fs-4 text-dark">Work</Link>
      </Col>
      <Col xxl={4} xl={4} lg={4} md={4} s={4} xs={4} className="text-center pe-1 ps-1 mt-1 mb-1">
        <Link to="/contact" className="text-uppercase fs-4 text-dark mt-1">Contact</Link>
      </Col>
      <Col xxl={4} xl={4} lg={4} md={4} s={4} xs={4} className="text-end pe-1 ps-1 mt-1 mb-1">
        <Link to="/about" className="text-uppercase fs-4 text-dark" style={{
        }}>About</Link>
      </Col>
    </Row>


    {/* <Container>
      <Navbar expand="lg">
        <Navbar.Brand href="/">{siteTitle}</Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarResponsive" />
          <Navbar.Collapse id="navbarResponsive">
            <Nav as="ul" className="ms-auto">
              <Nav.Item as="li">
                <Link to="/page-2" className="nav-link" activeClassName="active">Work</Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link to="/page-2" className="nav-link" activeClassName="active">About</Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link to="/page-2" className="nav-link" activeClassName="active">Contact</Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
      </Navbar>
    </Container> */}
    
  </header>
)

header.propTypes = {
  siteTitle: PropTypes.string,
}

header.defaultProps = {
  siteTitle: ``,
}

export default header;
